import useVisibilityChange from '@/hooks/useVisibilityChange';
import { Img } from '@chakra-ui/react';

const OriaBackground = () => {
  const video = document.getElementById('bgVideo') as HTMLMediaElement;
  useVisibilityChange(video?.play, video?.pause);

  return (
    <Img
      src="/oria/backgrounds/top.webp"
      position="absolute"
      loading="lazy"
      zIndex={-1}
      left="50%"
      top="0"
      transform="translateX(-50%)"
    />
  );
};

export default OriaBackground;
