import { Container, ContainerProps } from '@chakra-ui/react';

type TournamentSectionProps = ContainerProps & {
  name: string;
  isRow?: boolean;
  withBottomBorder?: boolean;
  withTopBorder?: boolean;
};

export const TournamentSection = ({
  name,
  isRow = false,
  children,
  withBottomBorder = false,
  withTopBorder = false,
  ...props
}: TournamentSectionProps) => {
  const withBorder = withBottomBorder || withTopBorder;

  return (
    <Container
      data-testid={`tournament-section-${name}`}
      as="section"
      display={'flex'}
      maxW="6xl"
      width="100%"
      mx="auto"
      marginBottom={['2rem']}
      py={withBorder ? 10 : 0}
      paddingX={['1rem', '1rem', '2rem']}
      borderColor="whiteAlpha.200"
      borderBottom={withBottomBorder ? '1px solid' : ''}
      borderBottomColor="whiteAlpha.200"
      borderTop={withTopBorder ? '1px solid' : ''}
      borderTopColor="whiteAlpha.200"
      flexDirection={isRow ? 'row' : 'column'}
      flexWrap={isRow ? 'wrap' : 'nowrap'}
      alignItems={isRow ? 'center' : 'start'}
      {...props}
    >
      {children}
    </Container>
  );
};
